import { Modal, usePolicyModals } from './components/Modal';
import OrderForm from './components/OrderForm';
import HeroContent from './components/HeroContent';
import TermsOfService from './components/TermsOfService';
import CheckoutSuccess from './components/CheckoutSuccess';

function App() {
  const { activeModal, openModal, closeModal } = usePolicyModals();
  const isCheckoutSuccess = window.location.pathname === '/checkout_successful';

  if (isCheckoutSuccess) {
    return <CheckoutSuccess />;
  }

  return (
    <div className="min-h-screen bg-base-200">
      {/* Brand Header */}
      <div className="container mx-auto p-4">
        <div className="font-bold text-xl">
          Driving Test Monitor UK
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto min-h-[calc(100vh-80px)] grid lg:grid-cols-2 gap-8 p-4">
        <HeroContent />
        <OrderForm />
      </div>

      {/* Footer */}
      <div className="bg-base-300">
        <div className="container mx-auto p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Company Info */}
            <div className="space-y-2">
              <h3 className="font-bold">Driving Test Monitor UK</h3>
              <p className="text-sm">Making driving test booking hassle-free</p>
            </div>

            {/* Quick Links */}
            <div className="space-y-2">
              <h3 className="font-bold">Legal</h3>
              <ul className="text-sm space-y-1">
                <li><a onClick={() => openModal('terms')} className="hover:underline cursor-pointer">Terms of Service</a></li>
              </ul>
            </div>

            {/* Contact */}
            <div className="space-y-2">
              <h3 className="font-bold">Contact</h3>
              <ul className="text-sm space-y-1">
                <li>Email: hello@drivingtestmonitor.uk</li>
                <li>Hours: Mon-Fri 9am-5pm</li>
              </ul>
            </div>

            {/* Policy modals */}
            <Modal isOpen={activeModal === 'terms'} onClose={closeModal}>
              <TermsOfService />
            </Modal>
          </div>
          <div className="mt-8 pt-4 border-t border-base-content/10 text-sm text-center">
            © {new Date().getFullYear()} Driving Test Monitor UK. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;