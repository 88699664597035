import React, { useState } from 'react';
import { BookingFormData } from './types';
import { useFormValidation } from './validation';
import centers from '../centers.json';

const OrderForm = () => {
    const [formData, setFormData] = useState<BookingFormData>({
        licenseNumber: '',
        testReferenceNumber: '',
        theoryPassNumber: '',
        testCenterId: '',
        testCenterName: '',
        earliestDate: '',
        latestDate: '',
        referenceType: 'testRef'
    });

    const {
        validateForm,
        validateField,
        getFieldError,
        errors,
        isSubmitting,
        setIsSubmitting
    } = useFormValidation();

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;

        // For number-only fields, remove non-digits
        if (name === 'testReferenceNumber' || name === 'theoryPassNumber') {
            const numericValue = value.replace(/\D/g, '');
            setFormData(prev => ({ ...prev, [name]: numericValue }));
            return;
        }

        // Handle special case for license number
        if (name === 'licenseNumber') {
            setFormData(prev => ({ ...prev, [name]: value.toUpperCase() }));
            return;
        }

        setFormData(prev => ({ ...prev, [name]: value }));

        // Live validation feedback
        const error = validateField(name as keyof BookingFormData, value);
        if (error) {
            console.log(`${name} error:`, error);
        }
    };

    // Calculate min/max dates
    const minDate = new Date(Date.now() + 86400000).toISOString().split('T')[0];
    const maxDate = new Date(Date.now() + 365 * 86400000).toISOString().split('T')[0];
    const addMonths = (date: Date, months: number): Date => {
        const newDate = new Date(date);
        newDate.setMonth(date.getMonth() + months);
        return newDate;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        const isValid = validateForm(formData);
        if (!isValid) {
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch('https://u4ahz8zc6k.execute-api.eu-west-2.amazonaws.com/checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': window.location.origin
                },
                credentials: 'include',
                body: JSON.stringify({
                    formData,
                    // Include any other necessary data
                }),
            });

            const { url } = await response.json();

            // Redirect to Stripe Checkout
            window.location.href = url;
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('Failed to process payment. Please try again.');
            setIsSubmitting(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <div className="card bg-base-100 shadow-xl">
                <div className="card-body">

                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* License Number Input */}
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Driving License Number</span>
                            </label>
                            <input
                                type="text"
                                name="licenseNumber"
                                className={`input input-bordered uppercase ${getFieldError('licenseNumber') ? 'input-error' : ''
                                    }`}
                                value={formData.licenseNumber}
                                onChange={handleInputChange}
                                placeholder="MORGA657054SM9IJ"
                                maxLength={16}
                            />
                            {getFieldError('licenseNumber') && (
                                <label className="label">
                                    <span className="label-text-alt text-error">
                                        {getFieldError('licenseNumber')}
                                    </span>
                                </label>
                            )}
                        </div>

                        {/* Reference Type Tabs */}
                        <div className="tabs tabs-boxed">
                            <a
                                className={`tab ${formData.referenceType === 'testRef' ? 'tab-active' : ''}`}
                                onClick={() => setFormData(prev => ({
                                    ...prev,
                                    referenceType: 'testRef',
                                    theoryPassNumber: ''
                                }))}
                            >
                                Test Reference #
                            </a>
                            <a
                                className={`tab ${formData.referenceType === 'theory' ? 'tab-active' : ''}`}
                                onClick={() => setFormData(prev => ({
                                    ...prev,
                                    referenceType: 'theory',
                                    testReferenceNumber: ''
                                }))}
                            >
                                Theory Test Pass #
                            </a>
                        </div>

                        {/* Reference Number Input */}
                        {formData.referenceType === 'testRef' ? (
                            <div className="form-control">
                                <input
                                    type="text"
                                    name="testReferenceNumber"
                                    className={`input input-bordered ${getFieldError('testReferenceNumber') ? 'input-error' : ''
                                        }`}
                                    value={formData.testReferenceNumber}
                                    onChange={handleInputChange}
                                    placeholder="Enter your test reference number"
                                />
                                {getFieldError('testReferenceNumber') && (
                                    <label className="label">
                                        <span className="label-text-alt text-error">
                                            {getFieldError('testReferenceNumber')}
                                        </span>
                                    </label>
                                )}
                            </div>
                        ) : (
                            <div className="form-control">
                                <input
                                    type="text"
                                    name="theoryPassNumber"
                                    className={`input input-bordered ${getFieldError('theoryPassNumber') ? 'input-error' : ''
                                        }`}
                                    value={formData.theoryPassNumber}
                                    onChange={handleInputChange}
                                    placeholder="Enter your theory test pass number"
                                />
                                {getFieldError('theoryPassNumber') && (
                                    <label className="label">
                                        <span className="label-text-alt text-error">
                                            {getFieldError('theoryPassNumber')}
                                        </span>
                                    </label>
                                )}
                            </div>
                        )}

                        {/* Test Center Selection */}
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Test Centre</span>
                            </label>
                            <select
                                name="testCenterId"
                                className={`select select-bordered w-full ${getFieldError('testCenterId') ? 'select-error' : ''
                                    }`}
                                value={formData.testCenterId}
                                onChange={(e) => {
                                    const selectedCenterId = e.target.value;
                                    const selectedCenter = centers.find(center => center.id === selectedCenterId);

                                    setFormData({
                                        ...formData,
                                        testCenterId: selectedCenterId,
                                        testCenterName: selectedCenter!.name,
                                    });
                                }}
                            >
                                <option value="">Select a test centre</option>
                                {centers.sort(
                                    (a, b) => a.name.localeCompare(b.name)
                                )
                                    .map(center => (
                                        <option key={center.id} value={center.id}>
                                            {center.name} - £{center.price}
                                        </option>
                                    ))}
                            </select>
                            {getFieldError('testCenterId') && (
                                <label className="label">
                                    <span className="label-text-alt text-error">
                                        {getFieldError('testCenterId')}
                                    </span>
                                </label>
                            )}
                        </div>

                        {/* Date Range Selection */}
                        <div className="grid grid-cols-2 gap-4">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Earliest Date</span>
                                </label>
                                <input
                                    type="date"
                                    min={minDate}
                                    max={maxDate}
                                    name="earliestDate"
                                    className={`input input-bordered ${getFieldError('earliestDate') ? 'input-error' : ''
                                        }`}
                                    value={formData.earliestDate}
                                    onChange={(e) => {
                                        const newEarliestDate = e.target.value;
                                        setFormData(prev => ({
                                            ...prev,
                                            earliestDate: newEarliestDate,
                                            latestDate: prev.latestDate && new Date(prev.latestDate) <= new Date(newEarliestDate) ?
                                                addMonths(new Date(newEarliestDate), 1).toISOString().split('T')[0] :
                                                prev.latestDate
                                        }));
                                    }}
                                />
                                {getFieldError('earliestDate') && (
                                    <label className="label">
                                        <span className="label-text-alt text-error">
                                            {getFieldError('earliestDate')}
                                        </span>
                                    </label>
                                )}
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Latest Date</span>
                                </label>
                                <input
                                    type="date"
                                    min={formData.earliestDate ?
                                        addMonths(new Date(formData.earliestDate), 1).toISOString().split('T')[0] :
                                        minDate}
                                    max={maxDate}
                                    name="latestDate"
                                    className={`input input-bordered ${getFieldError('latestDate') ? 'input-error' : ''
                                        }`}
                                    value={formData.latestDate}
                                    onChange={handleInputChange}
                                    disabled={!formData.earliestDate}
                                />
                                {getFieldError('latestDate') && (
                                    <label className="label">
                                        <span className="label-text-alt text-error">
                                            {getFieldError('latestDate')}
                                        </span>
                                    </label>
                                )}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className={`btn btn-primary w-full ${isSubmitting ? 'loading' : ''}`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Processing...' : 'Continue to Payment'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default OrderForm;