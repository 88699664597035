import React from 'react';

const TermsOfService = () => {
    return (
        <div className="card-body">
            <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
            <p className="text-sm opacity-70 mb-6">Last Updated: {new Date().toLocaleDateString()}</p>

            {/* Definitions */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">1. Definitions</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <p className="mb-2">1.1. "Company", "we", "us", or "our" refers to Strata Nexera Ltd, trading as Driving Test Monitor UK.</p>
                        <p className="mb-2">1.2. "Service" refers to the automated monitoring and booking service for DVSA driving test appointments.</p>
                        <p className="mb-2">1.3. "Monitoring Period" refers to the one-month period starting from the date of successful payment.</p>
                        <p className="mb-2">1.4. "Written Request" refers to an email sent to hello@drivingtestmonitor.uk.</p>
                        <div className="mb-2">
                            <p>1.5. "Valid Credentials" refers to a combination of:</p>
                            <ul className="list-disc pl-8 mt-2">
                                <li>Valid provisional UK driving licence number</li>
                                <li>Valid test booking reference number or theory test pass number</li>
                            </ul>
                            <p className="mt-2">that can successfully log into the DVSA test booking website.</p>
                        </div>
                        <p className="mb-2">1.6. "DVSA" refers to the Driver and Vehicle Standards Agency.</p>
                        <p className="mb-2">1.7. "Changeable Test" refers to an existing DVSA test booking that can be modified through DVSA's website.</p>
                    </div>
                </div>
            </section>

            {/* Service Description */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">2. Service Description</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">2.1. Service Overview</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">The Service monitors DVSA's test booking system for available driving test appointments.</li>
                            <li className="mb-2">Upon finding a slot matching user preferences, the Service attempts to book it using provided credentials.</li>
                            <li className="mb-2">The Service operates on a best-effort basis with no guarantee of securing an appointment.</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">2.2. Service Limitations</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">The Service requires an existing, changeable DVSA test booking.</li>
                            <li className="mb-2">The Service operates for one month from purchase date.</li>
                            <li className="mb-2">The Service does not guarantee:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Finding available slots</li>
                                    <li>Successful booking of found slots</li>
                                    <li>Continuous monitoring during DVSA maintenance or technical issues</li>
                                    <li>Response from DVSA systems</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">2.3. Service Access</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Available only to users within the United Kingdom</li>
                            <li className="mb-2">Requires UK IP address</li>
                            <li className="mb-2">Accepts only UK-issued payment cards</li>
                            <li className="mb-2">Requires UK billing address</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">2.4. Operating Hours</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Service monitoring operates 24/7 including holidays and weekends</li>
                            <li className="mb-2">Customer support processes requests within 48 hours, excluding weekends and holidays</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Eligibility & Registration */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">3. Eligibility & Registration</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">3.1. Eligibility Requirements</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Must possess a valid UK provisional driving licence</li>
                            <li className="mb-2">Must have an existing, changeable DVSA test booking</li>
                            <li className="mb-2">Must be legally eligible to book driving tests in the UK</li>
                            <li className="mb-2">Must provide Valid Credentials for DVSA website access</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">3.2. User Verification</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All credentials must be valid at time of purchase</li>
                            <li className="mb-2">Company reserves the right to verify eligibility at any time</li>
                            <li className="mb-2">False or inaccurate information may result in immediate service termination</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">3.3. Account Security</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Users are solely responsible for protecting their DVSA credentials</li>
                            <li className="mb-2">Users acknowledge that anyone with their DVSA credentials can:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Access their DVSA booking</li>
                                    <li>Change their test appointments manually</li>
                                    <li>Purchase our Service using their credentials</li>
                                </ul>
                            </li>
                            <li className="mb-2">Company bears no responsibility for unauthorized use of credentials</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">3.4. Security Breach Procedure</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Users suspecting unauthorized Service purchase must:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Submit a Written Request to stop monitoring</li>
                                    <li>Include clear photos of both sides of their provisional licence</li>
                                    <li>Requests processed within 48 business hours</li>
                                    <li>Service continues until request processed</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Payment & Fees */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">4. Payment & Fees</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">4.1. Payment Terms</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Full payment required upfront before Service commencement</li>
                            <li className="mb-2">Prices vary by test center</li>
                            <li className="mb-2">Accepted payment methods:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>UK-issued credit cards</li>
                                    <li>UK-issued debit cards</li>
                                    <li>3D Secure verification required</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">4.2. Payment Processing</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All payments processed through Stripe</li>
                            <li className="mb-2">UK billing address required</li>
                            <li className="mb-2">Service starts only after successful payment</li>
                            <li className="mb-2">Failed payments result in no Service activation</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">4.3. Chargebacks</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Initiating a chargeback results in:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Immediate Service termination</li>
                                    <li>Permanent user blacklisting</li>
                                    <li>No prior notification</li>
                                </ul>
                            </li>
                            <li className="mb-2">Company reserves the right to:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Contest all chargebacks</li>
                                    <li>Pursue legal action</li>
                                    <li>Recover:
                                        <ul className="list-square pl-8 mt-2">
                                            <li>Service fees</li>
                                            <li>Chargeback fees</li>
                                            <li>Legal costs</li>
                                            <li>Additional damages where applicable</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">4.4. Fee Structure</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Service fee as displayed at time of purchase</li>
                            <li className="mb-2">Refund deductibles (where applicable):
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Payment processing fee (2.5%)</li>
                                    <li>Administrative fee (£20)</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Refund Policy */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">5. Refund Policy</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">5.1. Refund Eligibility</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Within first week of purchase:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Full refund available (minus deductibles)</li>
                                    <li>Only if no test has been booked</li>
                                    <li>Must submit Written Request</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">5.2. Extended Refund Cases</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">After one month if:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>No test was booked AND</li>
                                    <li>User's latest date preference was more than two months from purchase date</li>
                                </ul>
                            </li>
                            <li className="mb-2">Exception: No refund if:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Existing test not changeable</li>
                                    <li>Test changing deadline passed</li>
                                    <li>Latest date preference earlier than two months from purchase</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">5.3. Non-Refundable Scenarios</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Test booked but user cannot attend</li>
                            <li className="mb-2">DVSA changes or cancels booked test</li>
                            <li className="mb-2">User finds slot elsewhere</li>
                            <li className="mb-2">Invalid credentials provided</li>
                            <li className="mb-2">Test center becomes unavailable (after first week)</li>
                            <li className="mb-2">User cancels existing test during monitoring</li>
                            <li className="mb-2">DVSA cancels existing test during monitoring</li>
                            <li className="mb-2">Any other scenario not explicitly eligible for refund</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">5.4. Refund Process</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All refund requests must be:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Submitted via Written Request</li>
                                    <li>Include order details</li>
                                    <li>State refund reason</li>
                                </ul>
                            </li>
                            <li className="mb-2">Processing time up to 48 business hours</li>
                            <li className="mb-2">Refund amount subject to deductibles:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>2.5% payment processing fee</li>
                                    <li>£20 administrative fee</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Service Modifications & Termination */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">6. Service Modifications & Termination</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">6.1. Service Duration</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Monitoring period limited to one month from purchase</li>
                            <li className="mb-2">Automatically terminates upon:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Successful test booking</li>
                                    <li>Reaching user's latest preferred date</li>
                                    <li>End of monitoring period</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">6.2. Service Modifications</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Requests immutable except:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Test center changes (Written Request required)</li>
                                    <li>Credential updates (Written Request required)</li>
                                </ul>
                            </li>
                            <li className="mb-2">Original purchase date remains reference for all timeframes</li>
                            <li className="mb-2">Changes processed within 48 business hours</li>
                            <li className="mb-2">Service continues under original terms during processing</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">6.3. Early Termination</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company may terminate service immediately if:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Chargeback initiated</li>
                                    <li>False information provided</li>
                                    <li>Terms of Service violated</li>
                                    <li>Legal compliance required</li>
                                </ul>
                            </li>
                            <li className="mb-2">No refund provided for Company-initiated termination</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">6.4. Service Interruption</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company not liable for interruptions due to:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>DVSA system maintenance</li>
                                    <li>Technical failures</li>
                                    <li>Network issues</li>
                                    <li>Changes to DVSA systems/policies</li>
                                </ul>
                            </li>
                            <li className="mb-2">Exception: Full refund if DVSA permanently prevents service operation</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Data Protection & Privacy */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">7. Data Protection & Privacy</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">7.1. Data Collection</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company collects and stores:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>DVSA credentials</li>
                                    <li>Email address</li>
                                    <li>Payment information</li>
                                    <li>Test preferences</li>
                                    <li>Service usage data</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">7.2. Data Storage</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All data stored securely on AWS cloud infrastructure</li>
                            <li className="mb-2">Data retained until:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>User requests deletion under GDPR</li>
                                    <li>Legal requirement mandates longer retention</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">7.3. Data Usage</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Data used exclusively for:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Providing the Service</li>
                                    <li>Monitoring test availability</li>
                                    <li>Booking tests via DVSA</li>
                                    <li>Communication about service status</li>
                                </ul>
                            </li>
                            <li className="mb-2">No data sharing except:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>With DVSA for booking purposes</li>
                                    <li>As required by law</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">7.4. Data Security</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company implements appropriate security measures</li>
                            <li className="mb-2">User responsibilities:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Maintain credential security</li>
                                    <li>Report suspected unauthorized access</li>
                                    <li>Update invalid credentials</li>
                                </ul>
                            </li>
                            <li className="mb-2">Company not liable for:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Unauthorized access due to user credential sharing</li>
                                    <li>Data breaches not caused by Company negligence</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Liability & Disclaimers */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">8. Liability & Disclaimers</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">8.1. Service Disclaimer</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Service provided "as is" without warranties</li>
                            <li className="mb-2">No guarantee of:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Finding available slots</li>
                                    <li>Successful bookings</li>
                                    <li>Continuous service availability</li>
                                    <li>DVSA system accessibility</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">8.2. Company Liability Limitations</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Not liable for:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Failed booking attempts</li>
                                    <li>DVSA system changes</li>
                                    <li>Test cancellations/modifications by DVSA</li>
                                    <li>User's inability to attend tests</li>
                                    <li>Invalid user credentials</li>
                                    <li>Test center availability changes</li>
                                    <li>Network/technical failures</li>
                                    <li>Unauthorized use of credentials</li>
                                    <li>Loss of existing test booking</li>
                                    <li>Any costs between user and DVSA</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">8.3. DVSA Relationship</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company not affiliated with DVSA</li>
                            <li className="mb-2">No responsibility for:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>DVSA policies</li>
                                    <li>DVSA system functionality</li>
                                    <li>DVSA test fees</li>
                                    <li>DVSA booking changes</li>
                                    <li>DVSA website availability</li>
                                </ul>
                            </li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">8.4. User Acknowledgments</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">User accepts:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>All risks associated with credential sharing</li>
                                    <li>Responsibility for DVSA compliance</li>
                                    <li>Monitoring limitations</li>
                                    <li>Booking attempt uncertainties</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Legal Jurisdiction & Disputes */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">9. Legal Jurisdiction & Disputes</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">9.1. Governing Law</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Terms governed by laws of England and Wales</li>
                            <li className="mb-2">Courts of England and Wales have exclusive jurisdiction</li>
                            <li className="mb-2">All proceedings conducted in English</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">9.2. Geographic Restrictions</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Service available only to UK residents</li>
                            <li className="mb-2">Access restricted to:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>UK IP addresses</li>
                                    <li>UK payment methods</li>
                                    <li>UK billing addresses</li>
                                </ul>
                            </li>
                            <li className="mb-2">Non-UK access attempts prohibited</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">9.3. Dispute Resolution</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All disputes subject to England and Wales jurisdiction</li>
                            <li className="mb-2">Company reserves right to:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Pursue legal action</li>
                                    <li>Recover costs</li>
                                    <li>Seek injunctive relief</li>
                                </ul>
                            </li>
                            <li className="mb-2">Legal notices sent to registered company address</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">9.4. Severability</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">If any provision deemed invalid:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Remaining terms remain in effect</li>
                                    <li>Invalid term replaced with valid equivalent</li>
                                    <li>Original intent preserved where possible</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Changes to Terms & Contact Information */}
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">10. Changes to Terms & Contact Information</h2>
                <div className="space-y-4">
                    <div className="pl-4">
                        <h3 className="text-xl font-semibold mb-2">10.1. Terms Modifications</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Company reserves right to modify terms at any time</li>
                            <li className="mb-2">Changes effective immediately upon posting</li>
                            <li className="mb-2">Continued service use constitutes acceptance</li>
                            <li className="mb-2">No individual notification required</li>
                            <li className="mb-2">Users responsible for reviewing terms regularly</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">10.2. Company Information</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Strata Nexera Ltd</li>
                            <li className="mb-2">Trading as: Driving Test Monitor UK</li>
                            <li className="mb-2">Company registered in England and Wales</li>
                            <li className="mb-2">Email: hello@drivingtestmonitor.uk</li>
                            <li className="mb-2">Support Hours: Monday-Friday, 9am-5pm (excluding holidays)</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">10.3. Communications</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">All service communications via email</li>
                            <li className="mb-2">User responsible for:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Maintaining valid email address</li>
                                    <li>Checking email regularly</li>
                                    <li>Adding company email to safe senders</li>
                                </ul>
                            </li>
                            <li className="mb-2">48-hour response time for all requests (business days only)</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-2 mt-4">10.4. Acceptance of Terms</h3>
                        <ul className="list-disc pl-8">
                            <li className="mb-2">Use of service constitutes acceptance of these terms</li>
                            <li className="mb-2">Users warrant they:
                                <ul className="list-circle pl-8 mt-2">
                                    <li>Have read these terms</li>
                                    <li>Understand their obligations</li>
                                    <li>Accept all conditions</li>
                                    <li>Are legally capable of entering this agreement</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsOfService;