function CheckoutSuccess() {

    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');

    return (
        <div className="min-h-screen bg-base-200">
            {/* Brand Header */}
            <div className="container mx-auto p-4">
                <div className="font-bold text-xl">
                    Driving Test Monitor UK
                </div>
            </div>

            {/* Success Content */}
            <div className="container mx-auto min-h-[calc(100vh-80px)] flex items-center justify-center p-4">
                <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
                    <div className="mb-6">
                        <svg
                            className="mx-auto h-16 w-16 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                    <h1 className="text-2xl font-bold mb-4">
                        Thank You for Your Purchase!
                    </h1>
                    <p className="text-gray-600 mb-6">
                        Your order has been confirmed. You will receive a confirmation email shortly with your order details.
                    </p>
                    <a
                        href="/"
                        className="btn btn-primary text-white px-6 py-2 rounded-lg"
                    >
                        Return to Homepage
                    </a>
                </div>
            </div>

            {/* Footer */}
            <div className="bg-base-300">
                <div className="container mx-auto p-8">
                    <div className="text-sm text-center">
                        © {new Date().getFullYear()} Driving Test Monitor UK. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckoutSuccess;