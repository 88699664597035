import React, { useState } from 'react';


const HeroContent = () => {


    return (
        <div className="flex flex-col justify-center space-y-6">
            <h1 className="text-5xl font-bold">Secure Your Driving Test Slot</h1>
            <p className="text-xl">
                Skip the waiting game. Let us monitor and secure your ideal driving test appointment while you focus on practice.
            </p>
            <div className="space-y-4">
                <div className="flex items-center gap-2">
                    <span className="text-2xl">✓</span>
                    <span>Choose your preferred test center and dates</span>
                </div>
                <div className="flex items-center gap-2">
                    <span className="text-2xl">✓</span>
                    <span>Continuous monitoring</span>
                </div>
                <div className="flex items-center gap-2">
                    <span className="text-2xl">✓</span>
                    <span>Automatic booking when an appointment becomes available</span>
                </div>
                <div className="flex items-center gap-2">
                    <span className="text-2xl">✓</span>
                    <span>From only £60</span>
                </div>
            </div>
        </div>
    );
};

export default HeroContent;